.blog-top {
  margin-top: 30px;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .breadcrumb-wrapper {
    padding-top: 0;
    margin-top: 0;
  }
}

.blog-share {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  strong {
    margin-right: 10px;
    position: relative;
  }
}

.a2a_kit {
  a {
    float: left;
    line-height: 16px;
    padding: 0 2px;
  }
}

.newsroom-meta {
  color: #5a5a5a;
  margin-top: 15px;
  font-size: 0.92rem;
}

@media (max-width: 930px) {
  .blog-top {
    .content-wrapper {
      flex-wrap: wrap;
    }

    .breadcrumb-wrapper {
      width: 100%;
    }
  }

  .blog-share {
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: -10px;
  }
}

@include tabletPortrait {
  .blog-top {
    margin-top: 10px;
  }
}

@media (max-width: 620px) {
  .blog-share {
    margin-left: -2px;
    margin-bottom: 0;
    margin-top: 15px;

    strong {
      display: none;
    }
  }
}

// Article Styles
.blog-article {
  .category {
    margin-bottom: 20px;
    display: inline-block;
    font-size: 14px;
    padding-bottom: 4px;
  }

  .article-data {
    font-weight: 600;
    padding-bottom: 6px;
    padding-left: 6px;
  }
}

.post-meta {
  font-size: 13px;
  font-weight: 500;
  color: #6f6f6f;
  margin-top: 10px;
}

.blog-section {
  --content-max-width: calc(var(--max-width, 780px) + var(--content-padding) * 2);
}

.ktc-section:has(.blog-section) {
  margin-bottom: var(--section-padding);

  &:last-child {
    margin-bottom: 0;
  }
}

.post-content {
  margin-top: 35px;
  line-height: 1.5;
  font-size: 16px;

  &:first-child {
    margin-top: 0;
  }

  > .section {
    &:first-child {
      padding-top: 0;
    }

    &:last-child:not(.testimonial) {
      padding-bottom: 0;
    }
  }

  .blog-section {
    margin-top: var(--section-padding);
    margin-bottom: var(--section-padding);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 30px;
    margin-bottom: 12px;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 24px;
    font-family: $bodyFont;
    letter-spacing: 0 !important;
    font-weight: 600;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-family: $bodyFont;

    + ol,
    + ul {
      margin-top: 20px;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 10px;
  }

  h5 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
  }

  p,
  li {
    // font-size: 1rem;
    font-size: inherit;
  }

  p {
    margin-bottom: 15px;
  }

  ul,
  ol {
    margin-bottom: 18px;
    margin-left: 20px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 18px;

    ul {
      margin-top: 12px;
    }

    &:not(:last-child) {
      > ul {
        padding-bottom: 10px;
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  @include tablet {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 25px;
    }

    // h2 {
    //   font-size: 33px;
    // }

    // h3 {
    //   font-size: 26px;
    // }

    // h4 {
    //   font-size: 17px;
    // }

    // h5 {
    //   font-size: 16px;
    // }
  }

  @include tabletPortrait {
    margin-top: 20px;

    // h2 {
    //   font-size: 31px;
    // }

    // h3 {
    //   font-size: 25px;
    // }
  }
}

.blog-column {
  max-width: 880px;
}

.blog-callout-box {
  padding: 35px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin: 30px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.25em;
    margin-bottom: 15px;

    &:first-child {
      margin-top: -0.1em;
    }
  }
}

.blog-box-wrapper {
  --blog-box-gap: 30px;
  --bg-color: var(--blog-box-color, #f9f9f9);

  .content-wrapper {
    display: flex;

    @at-root .ktc-section & {
      display: block;
    }
  }

  .blog-box {
    background-color: var(--bg-color);
    flex-grow: 1;
    width: 100%;
    padding: 35px;
    border-radius: 5px;
    margin-right: var(--blog-box-gap);

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .ktc-widget-zone {
    display: flex;
    gap: var(--blog-box-gap);
  }

  .ktc-admin-ui {
    display: contents;
  }

  .ktc-widget {
    flex-grow: 1;
    width: 100%;
    background-color: var(--bg-color);

    .blog-box {
      border-radius: 0;
    }
  }

  @media (max-width: 820px) {
    .content-wrapper,
    .ktc-widget-zone {
      flex-direction: column;
    }

    .blog-box {
      margin-right: 0;
      margin-bottom: var(--blog-box-gap);
    }
  }
}

#blog-back {
  padding-top: 0;
  border-top: 1px solid transparent;

  .content {
    border-top: 1px solid #e1e1e1;
    padding-top: 30px;

    a {
      font-size: inherit !important;
    }
  }
}

.blog-article {
  iframe,
  .mce-preview-object.mce-object-iframe {
    width: 100%;
    display: block;
    margin: 2.3em 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  iframe {
    height: 315px;

    @media (min-width: 881px) {
      height: 455px;
    }
  }

  .mce-preview-object.mce-object-iframe {
    iframe {
      margin: 0;
      pointer-events: none;
    }
  }
}

.blog-bottom {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: var(--section-padding);
  }

  @media (max-width: 585px) {
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    .section-top {
      margin-bottom: 35px;
    }
  }
}

.blog-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;

  .ltr-underline,
  .link-underline-left {
    display: flex !important;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 0;

      &:after {
        top: 0.12em;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .ltr-underline {
    text-wrap: balance;
    justify-content: flex-end;
    text-align: right;

    span {
      margin-left: 0.5em;
    }
  }

  .link-underline-left {
    span {
      margin-right: 0.5em;
    }
  }

  @media (min-width: 585px) {
    border-top: 1px solid #dedede;
    padding-top: 20px;
  }

  @media (max-width: 584px) {
    @at-root #main & {
      display: block;
      font-size: 16px;

      .ltr-underline,
      .link-underline-left {
        text-align: left;
        justify-content: space-between;
        padding: 15px 0 14px 0;
        border-bottom: 1px solid #dedede;

        &:first-child {
          border-top: 1px solid #dedede;
        }

        &:after {
          display: none !important;
        }

        span {
          transform: none !important;
          margin-right: 0 !important;
          margin-left: 0.5em;

          &:after {
            transform: none !important;
            margin: 0;
            transition: none;
          }
        }
      }

      .link-underline-left {
        span {
          order: 2;
        }
      }
    }
  }
}

// Temporarily hide post meta until
// component is updated to make optional
.blog-article .post-meta {
  display: none;
}
