.btn {
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: 600;

  sup {
    font-weight: 400;
  }

  @include media-breakpoint-down(md) {
    padding: 0.35rem 1rem;
  }
}

.btn-primary,
.btn-outline-primary,
.btn-success,
.btn-outline-success-alt {
  padding: 0.63em 1.35em 0.64em 1.35em;

  @include media-breakpoint-down(md) {
    padding: 0.63em 1.35em 0.633em 1.35em;
    line-height: 1.4;
  }

  @at-root {
    .section-bottom .buttons-wrapper &,
    .section-bottom.buttons-wrapper &,
    .section-cta &,
    .cta-button &,
    &.large {
      padding: 0.75em 1.8em 0.76em;
      font-size: 0.87em;
      min-width: 150px;
    }
  }
}

main {
  .btn-outline-primary {
    color: $darkTealColor;
  }
}

.btn-success {
  border-color: $white;

  &:hover {
    color: $green;
    background-color: $white;
    border-color: $white;
  }
}

.btn-outline-success-alt {
  border-color: $white;
  color: $white;
  background-color: transparent;
  &:hover {
    background-color: $white;
    color: $green !important;
  }
}

/* faq button links */
.btn-faq-general {
  background-color: #008080;
  color: $white;
}

.btn-faq-business-banking {
  background-color: #e1702c;
  color: $white;
}

.btn-faq-digital-banking {
  background-color: #176980;
  color: $white;
}

.btn-faq-home-loans {
  background-color: #82afc6;
  color: $white;
}

.btn,
.btn-primary,
.btn-outline-primary {
  &.loading {
    pointer-events: none;
    border-color: #adabab !important;
    color: #828282 !important;
    background-color: rgba(#dcdcdc, 0.12) !important;
    transition: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

// Page builder button widget
.content-btn {
  margin: 4px 4px 4px 0;
  vertical-align: middle;

  + p {
    margin-top: calc(1rem + 2px);
  }

  &:has(+ .ltr-underline) {
    margin-right: 15px;
  }

  @include pageBuilder {
    box-shadow: none !important;
    cursor: text !important;

    &:focus,
    &:active {
      background-color: inherit;
    }

    &.btn-primary {
      background-color: $tealColor !important;
      border-color: $tealColor !important;
      color: #fff !important;
    }

    &.btn-outline-primary {
      background-color: transparent !important;
      border-color: $darkTealColor !important;
      color: $tealColor !important;

      @at-root {
        .text-white &,
        &.text-white {
          color: #fff !important;
          border-color: #fff !important;
        }
      }
    }
  }
}

.ktc-widget {
  &[data-widget-type='button-widget'],
  &:is(:has(.content-btn-widget)) {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 33px;
    min-height: 77px !important;
    margin-right: 8px;

    .content-btn {
      margin: 0 !important;
      box-shadow: none !important;
    }
  }
}
