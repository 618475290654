:root {
  // Colors
  --body-text-color: #{$bodyColor};
  --teal-color: #{$tealColor};
  --gold-color: #{$goldColor};

  // Layout
  --content-width: #{$contentMaxWidth};
  --section-padding: #{$sectionPaddingDesktop};
  --content-padding: #{$contentPaddingDesktop};

  @media (max-width: #{$contentMaxWidth}) {
    --section-padding: #{$contentPaddingDesktop + 10px};
  }

  @media (max-width: #{$tabletMaxWidth}) {
    --section-padding: #{$sectionPaddingTablet};
    --content-padding: #{$contentPaddingTablet};
  }

  @media (max-width: #{$tabletPortraitMaxWidth}) {
    --section-padding: #{$sectionPaddingTabletPortrait};
    --content-padding: #{$contentPaddingTabletPortrait};
  }

  @media (max-width: #{$mobileMaxWidth}) {
    --section-padding: #{$sectionPaddingMobile};
    --content-padding: #{$contentPaddingMobile};
  }
}
